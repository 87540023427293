<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <AddNewContent name="lh-content-community-create" />

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="title">ชื่อ</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.id)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
// import get from 'lodash/get'
import { computed } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import useCommunityUtil from '@/use/useCommunityUtil'
import omit from 'lodash/omit'
import get from 'lodash/get'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import { getUniqueValue } from '@/helper/Utils'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'CommunityListView',
  components: { PopupLanguage, AddNewContent, LhDropdownPerPage, CustomTable },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'community')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData } = useCommunityUtil(ctx)

    crudFunction.routePrefix.value = 'lh-content-community'

    const items = computed(() => {
      return crudFunction.result.value
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'community/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      const title = formData.title.concat('_copy')

      const items = crudFunction.result.value

      formData.title = getUniqueValue(items, title, 'title')

      if (formData.slug) {
        const slug = formData.slug.concat('_copy')
        formData.slug = getUniqueValue(items, slug, 'slug')
      }

      if (formData.custom_slug) {
        const custom_slug = formData.custom_slug.concat('_copy')
        formData.custom_slug = getUniqueValue(items, custom_slug, 'custom_slug')
      }

      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      const created_by = email
      const updated_by = email

      const resultDuplicate = await ctx.root.$store.dispatch(
        'community/addItem',
        {
          ...omit(formData, ['id', 'revision_id', 'updated_at']),
          created_by,
          updated_by
        },
      )
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: resultDuplicate.id,
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    return {
      ...crudFunction,
      items,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      searchKeys: ['title']
    }
  },
}
</script>

<style scoped></style>
